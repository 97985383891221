.assets-filters {
    .list-group-item {
        text-align: center;
        padding: 20px;
        @for $i from 1 through length($assetBackgrounds) {
            &:nth-child(#{length($assetBackgrounds)}n+#{$i}) {
                a {
                    background-color: nth($assetBackgrounds, $i);
                    color: $black;
                    &:hover, &.is-active {
                        &:after {
                            top: -15px;
                            left: -15px;
                            bottom: -15px;
                            right: -15px;
                            background-color: lighten(nth($assetBackgrounds, $i), 20%);
                        }
                    }
                }
            }
        }
        &.clear-button {
            a {
                background-color: $yellow;
                &:hover, &.is-active {
                    &:after {
                        background-color: lighten($yellow, 20%);
                    }
                }
            }
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            border-radius: 50%;
            width: 100%;
            margin: 0 auto;
            @extend %secondary-font;
            font-size: 30px;
            border: 3px solid $black;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: -1;
                border-radius: 50%;
                transition: all 0.3s;
            }
        }
    }
}