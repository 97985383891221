@font-face {
    font-family: "ramona_displayregular";
    src: url("../fonts/ramona_display-webfont.woff2") format("woff2"), url("../fonts/ramona_display-webfont.woff") format("woff"), url("../fonts/ramona_display-webfont.ttf") format("truetype"), url("../fonts/ramona_display-webfont.svg#ramona_displayregular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ramona_normalregular";
    src: url("../fonts/ramona_normal-webfont.woff2") format("woff2"), url("../fonts/ramona_normal-webfont.woff") format("woff"), url("../fonts/ramona_normal-webfont.ttf") format("truetype"), url("../fonts/ramona_normal-webfont.svg#ramona_normalregular") format("svg");
    font-weight: normal;
    font-style: normal;
}

%primary-font {
    font-family: "ramona_displayregular", "Patrick Hand", cursive;
    text-transform: uppercase;
}

%secondary-font {
    font-family: "ramona_normalregular", "Patrick Hand", cursive;
    text-transform: uppercase;
}

%main-font {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: $base-font-size;
    line-height: $base-font-size + 5;
    text-transform: none;
}

h1,
.primary-font {
    @extend %primary-font;
}

.primary-text-colour {
    color: $lynch;
}

h2,
h3,
.secondary-font {
    @extend %secondary-font;
}

.secondary-text-colour {
    color: $pink;
}

h4,
h5,
body,
.main-font {
    @extend %main-font;
}

.main-text-colour {
    color: $body-font-color;
}

h1 {
    font-size: $h1-font-size;
    line-height: $h1-font-size + 5;
    margin-bottom: 20px;
}

h2 {
    font-size: $h2-font-size;
    line-height: $h2-font-size;
    margin-bottom: 10px;
}

h3 {
    font-size: $h3-font-size;
    line-height: $h3-font-size;
    margin-bottom: 10px;
}

h4 {
    font-size: $h4-font-size;
    line-height: $h4-font-size + 5;
    margin-bottom: 10px;
}

h5 {
    font-size: $h5-font-size;
    line-height: $h5-font-size + 5;
    margin-bottom: 10px;
}

blockquote {
    font-size: 30px;
    line-height: 30px;
    @extend %secondary-font;
    cite {
        font-style: normal;
        text-transform: none;
    }
}

.home-blockquote {
    blockquote {
        color: $white;
        font-size: 30px;
        line-height: 30px;
        @extend %secondary-font;
        @include breakpoint(md) {
            font-size: 60px;
            line-height: 60px;
        }
        cite {
            font-size: 20px;
            line-height: 20px;
            @include breakpoint(md) {
                font-size: 30px;
                line-height: 30px;
            }
        }
    }
}

.separator {
    padding-top: 30px;
    border-top: 3px solid $section-border-top;
}

a {
    color: $body-font-color;
    &:hover {
        color: $pink;
    }
}

p {
    margin-bottom: 1em;
}

.quiz-button {
    @extend %secondary-font;
    @include siteButton($pink,
    $white);
    border-radius: 50px;
    font-size: 30px;
    line-height: 30px;
    
}

.primary-button {
    @extend %primary-font;
    border-radius: 50px;
    @include siteButton($primary-color,
    $white);
    font-size: 30px;
    line-height: 30px;
}

.secondary-button {
    @extend %secondary-font;
    @include siteButton($secondary-color,
    $white);
    border-radius: 50px;
    font-size: 30px;
    line-height: 30px;
    display: inline-block;
    padding: 15px;
    &__character {
        display: inline-block;
        text-align: center;
    } 
}

.white-button {
    @extend %secondary-font;
    @include siteButton($white,
    $secondary-color);
}

.back-button { 
    margin: 20px 0;
    a {
        &:hover {
            color: $black;
        }
    }
}