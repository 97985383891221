.hero__container {
    &--left {
        text-align: center;
        @include breakpoint(md) {
            text-align: left;
        }
    }
    h1 {
        color: $pink;
        font-size: 100px;
        line-height: 100px;
        @include breakpoint(large-tablet-landscape) {
            font-size: 120px;
            line-height: 120px;
        }
        @include breakpoint(large-desktop) {
            font-size: 150px;
            line-height: 150px;
        }
    }
    h2 {
        color: $dark-blue;
        font-size: 40px;
        line-height: 30px;
        @include breakpoint(large-tablet-landscape) {
            font-size: 60px;
            line-height: 40px;
        }
    }
    .component__link {
        a {
            background-color: $pink;
            color: $white;
            font-size: 30px;
            &:hover {
                background-color: $white;
                color: $pink;
            }
        }
    }
}