@import "variables";
@import "typography";

body {
    background: $background-body;
}

main {
    overflow: hidden;
}

.white-background {
    background-color: $white;
    @include full_width_colour($white);
}

.pink-background {
    background-color: $pink;
    @include full_width_colour($pink);
}

.light-pink-background {
    background-color: $light-pink;
    @include full_width_colour($light-pink);
}

.light-green-background {
    background-color: $light-green;
    @include full_width_colour($light-green);
}

.yellow-background {
    background-color: $yellow;
    @include full_width_colour($yellow);
}