.book-detail {
    .book-content {
        .buy-button-wrapper {
            background-color: $yellow;
            @media (max-width: 767px) {
                padding: 16px;
            }
            .format {
                @media (max-width: 767px) {
                    font-size: 24px;
                    margin-bottom: 8px;
                }
            }
            .price {
               @media (max-width: 767px) {
                    font-size: 22px;
                }
            }
            .buy-button {
                background-color: $orange;
                &:hover {
                    background-color: $dark-blue;
                    color: $white;
                }
            }
        }
    }
    .product-tabs {
        .flextabs {
            &__toggle {
                color: $dark-blue;
                text-transform: uppercase;
                &--active {
                    &--last {
                        @media (min-width: 768px) {
                            border-color: $pink $pink $white;
                            border-width: 3px;
                            border-bottom: 0;
                            margin-bottom: -3px;
                        }
                    }
                }
            }
            &__content:after, &__content:before {
                border-top: 3px solid $pink;
                margin-top: -2px;
            }
            &__content {
                &--active {
                    &--last {
                        @media (min-width: 768px) {
                            border-top: 3px solid $pink;
                        }
                    }
                }
            }
        }
    }
    .other-formats {
        ul {
            li {
                a {
                    color: $orange;
                }
            }
        }
    }
    .button-asset {
        padding: 0;
        a {
            display: block;
            padding: 15px 30px;
            color: $white;
        }
        &--audio {
            padding: 15px 30px;
            background-color: $white;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
                0 2px 4px -1px rgba(0, 0, 0, 0.08);
        }
        &.rounded-full {
            font-size: 24px;
            &:hover {
                background-color: $dark-blue;
                color: $white;
            }
        }
    }
    .related-books {
        &-title {
            position: relative;
            margin-top: 0 !important;
            padding-top: 0 !important;
            @media (max-width: 767px) {
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    background: $background-body;
                    width: 9999px;
                    z-index: 3;
                    height: 100%;
                    right: 95%;
                    border-top: 3px solid $pink;
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    background: $background-body;
                    width: 9999px;
                    z-index: 3;
                    height: 100%;
                    left: 95%;
                    border-top: 3px solid $pink;
                }
            }
            h1 {
                @extend %secondary-font;
                font-size: 50px;
                color: $pink;
                border-top: 3px solid $pink;
                padding-top: 30px;
            }
        }
    }
}
