$blue: #74bcc8;
$lynch: #60808b;
$pink: #c65855;
$light-pink: #da8573;
$yellow: #eac44c;
$dark-blue: #003769;
$black: #000;
$green: #88b984;
$light-green: #8cc1a4;
$white: white;
$max-width: 1060px;
$grey: #5f5f5f;
$orange: #cb4e34;
$purple: #8174b5;
$animation-time: 0.3s;
$section-border-top: $pink;
$background-body: #fdf4da;
$body-font-color: $black;
$primary-color: $dark-blue;
$secondary-color: $pink;


$assetBackgrounds: $pink, $blue, $orange, $green, $purple, $orange; 

/* Header */

$header-background-color: $blue;

/* NAVIGATION */

$nav-links-colour: $white;
$nav-links-colour-hover: $yellow;
$nav-current-link-colour: $yellow;
$nav-background-colour: $pink;

/* default font sizes */

$base-font-size: 18px;
$h1-font-size: 60px;
$h2-font-size: 50px;
$h3-font-size: 35px;
$h4-font-size: 20px;
$h5-font-size: 18px;