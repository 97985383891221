.newsletter {
    h2 {
        text-align: center;
        @media (min-width: 768px) {
            text-align: left;
        }
    }
    .sailthru-form {
        &__dob {
            .select-style {
                border-radius: 8px;
            }
        }
    }
}