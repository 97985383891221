.pagination-wrapper {
    @extend %secondary-font;
    font-size: 25px;
    p {
        margin-bottom: 0;
    }
    .pagination {
        li {
            a {
                background-color: transparent;
                border-radius: 5px;
                padding: 5px 8px;
                &.is-active,
                &:hover {
                    background-color: $secondary-color;
                    color: white;
                }
            }
        }
    }
}