.beverly-cleary-quiz {
    background: #776d9e;
    .form__input-index {
        background-color: #3f677c;
        @extend %secondary-font;
        font-size: 28px;
        line-height: 28px;
    }
    button[type=submit] {
        @extend %secondary-font;
        @include siteButton(#c65855, $white);
        min-width: 150px;
        font-size: 30px;
        line-height: 30px;
    }
}